@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.StatusBar {
  @extend .display-flex;
  @extend .flex-center-end;
  @extend .bsbb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: $zi-status-bar;
  background: $white;
  @extend .trstn;
  img {
    margin: 0 10px;
  }
  @media screen and (min-width: 320px) {
    padding: 10px;
  }
  @media screen and (min-width: 768px) {
    padding: 10px 20px 10px 270px;
  }
  @extend .trstn;
}

.Shadow {
  @extend .trstn;
  @include box-shadow(0, 0, 10px, 0, rgba($my-dark, 0.5));
}

.ProfileName {
  @extend .stratumbold;
  text-transform: uppercase;
  @extend .display-flex;
  @extend .flex-center;
}

.Menu {
  @extend .flex-center;
  @extend .display-flex;
  @extend .trstn;
  position: fixed;
  top: 15px;
  left: 10px;
  width: 30px;
  height: 30px;
  z-index: $zi-mobile-menu;
}

.MenuOpen {
  left: 260px;
  z-index: $zi-mobile-menu;
  @extend .trstn;
  > div {
    > button {
      > span {
        > span {
          background: $white !important;
          &:after {
            background: $white !important;
          }
        }
      }
    }
  }
  // &:before {
  //   content: '';
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba($my-blue, 0.7);
  // }
}

.QrContainer {
  // i {
  // }
  margin-left: 10px;
  cursor: pointer;
}

.MobileMenuOpenBkg {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($my-blue, 0.7);
}

.BarContainer {
  color: $my-blue;
  font-size: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.Vip {
  color: $my-yellow;
  margin-left: 10px;
}
