@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.UlNav {
  // padding-top: 20px;
  padding-right: 20px;
  overflow-y: auto;
  @include calc('height', '100% - 90px');
  // background: red;
  @extend .bsbb;
}

.NavLink {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 15px;
  display: inline-block;
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
  color: $white;
  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    background: $my-yellow;
    transition: width 1s ease 0s, right 0.5s ease 0s;
    width: 0;
  }
  &:hover {
    &:after {
      width: 100%;
      left: 0;
    }
  }
}

.LiAdmin {
  margin-top: 20px;
}

.LiLogout {
  margin-top: 20px;
  button {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
}
