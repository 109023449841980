@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.Footer {
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  @extend .stratumbold;
  font-size: 10px;
  padding: 20px 0 10px 0;
  width: 100%;
  @extend .bsbb;
  img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
  }
}
