@import '../../../styles/colors';
@import '../../../styles/mixin';

.BarCode {
  @extend .display-flex;
  @extend .flex-center;
  background: rgba($my-red, 0.9);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index:$zi-qrcode;
  span {
    padding: 20px;
    background: $white;
    @include border-radius(2px);
  }
}