@import '../../../styles/colors';
@import '../../../styles/mixin';

.UlBone {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  height: 100%;
}

.BoneMenu {
  background: $my-blue;
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  @extend .bsbb;
  padding: 20px;
  z-index: $zi-menu;
  @media screen and (min-width: 320px) {
    left: -250px;
  }
  @media screen and (min-width: 768px) {
    left: 0;
  }
  @extend .trstn;
}

.MenuOpen {
  left: 0;
  @extend .trstn;
}

.BoneContent {
  width: 100%;
  @extend.bsbb;
  @media screen and (min-width: 320px) {
    padding: 60px 10px 0 10px;
  }
  @media screen and (min-width: 768px) {
    padding: 60px 20px 0 270px;
  }
}

.UlContent {
  @extend .display-flex;
  width: 100%;
  border: 1px solid $grey-300;
  @media screen and (min-width: 320px) {
    @include flex-column;
  }
  @media screen and (min-width: 1024px) {
    @include flex-row;
  }
}

.Content {
  width: 100%;
}

.Sponsor {
  @media screen and (min-width: 320px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    min-width: 250px;
    max-width: 250px;
  }
}

.LinkLogo {
  text-decoration: none;
  display: block;
  height: 70px;
  padding-bottom: 20px;
}
