@import '../../../../styles/colors';
@import '../../../../styles/mixin';

.ScrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  @extend .display-flex;
  @extend .flex-center;
  background: $my-blue;
  color: $white;
  @include border-radius(50%);
  z-index: $zi-scroll-top;
  cursor: pointer;
  @include box-shadow(0, 0, 5px, 0, rgba($black, 0.5));
  i {
    animation: MoveUpDown 3s linear infinite;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    margin-top: -5px;
  }
  50% {
    margin-top: 5px;
  }
}
